import { App } from '@inertiajs/inertia-svelte'
import Honeybadger from 'honeybadger-js'
import '../stylesheets/application.scss'
import axios from 'axios'
import { loadStripe } from '@stripe/stripe-js'

axios.defaults.xsrfHeaderName = 'X-CSRF-Token'

document.addEventListener('DOMContentLoaded', async () => {
  const el = document.getElementById('app')
  const initialPage = JSON.parse(el.dataset.page)
  const { honeybadger, stripe } = initialPage.props
  window.stripe = await loadStripe(stripe.publicKey, {
    betas: ['custom_checkout_beta_1'],
  })

  if (honeybadger.environment !== 'development') {
    Honeybadger.configure({
      apiKey: honeybadger.apiKey,
      environment: honeybadger.environment,
    })
  }

  new App({
    target: el,
    props: {
      initialPage,
      resolveComponent: (name) => import(`../Pages/${name}.svelte`),
    },
  })
})
